
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonAvatar,
  IonIcon,
  IonButton,
  IonButtons,
  IonSpinner,
  menuController,
  modalController,
  onIonViewWillLeave,
} from "@ionic/vue";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { menu } from "ionicons/icons";
import { openToast } from "@/services/toast";

import apiProdotti from "@/services/prodotti";

import Quagga from 'quagga';

export default {
  name: "TestBarcode",
  components: {
      IonPage,
      IonHeader,
      IonToolbar,
      IonTitle,
      IonContent,
      //IonAvatar,
      IonIcon,
      IonButton,
      IonButtons,
      //IonSpinner,
      //QRCodeVue3,
  },
  setup() {

    const openMenu = () => {
            menuController.open("app-menu");
        };
          
    // References and reactive variables
    const scannerContainer = ref(null);
    const result = ref('');
    const errorMessage = ref('');
    const isPaused = ref(false);
    const isScanning = ref(false);
    const products = ref([]);

    const test = ref('');


    // Barcode detected
    async function onDetected(data) {
      console.log(data);
      console.log(isPaused.value);
      
        
        if (isPaused.value) return;

        test.value = data.codeResult.code;

        const code = data.codeResult.code;
        result.value = code;

        // Pause the scanner and disable detection
        isPaused.value = true;
        Quagga.offDetected(onDetected);
        Quagga.stop();

        setTimeout(() => {
          console.log('Resuming scanning...');
          isPaused.value = false;
          // eslint-disable-next-line no-use-before-define
          initQuagga();
        }, 3000); // Resume scanning after 3 seconds



        try {
          const response = await apiProdotti.searchProductByBarcode(code);

          if (response.status === 200 && response.data.status === 0 && response.data.data.length > 0) {
            products.value.push(response.data.data[0]);
          } else {
            openToast('Nessun prodotto trovato', 'toast_danger');
          }
        } catch (err) {
          console.error('API error:', err);
          openToast('Errore durante la ricerca del prodotto', 'toast_danger');
        } finally {
          console.log('fine scansione');
        }
    }

    // Initialize QuaggaJS
    function initQuagga() {
      Quagga.init({
        inputStream: {
          type: 'LiveStream',
          target: scannerContainer.value, // Or '#yourElement' (optional)
          constraints: {
            width: 350, // Adjust width and height in percentage
            height: 350, // Adjust width and height in percentage
            facingMode: 'environment', // or 'user' for front camera
          },
        },
        decoder: {
          multiple: false,
          //readers: ['code_128_reader', 'ean_reader', 'ean_8_reader', 'code_39_reader', 'code_39_vin_reader', 'upc_reader', 'upc_e_reader', 'codabar_reader', 'i2of5_reader', '2of5_reader', 'code_93_reader'],
          readers: ['code_128_reader', 'ean_reader', 'ean_8_reader', 'code_39_reader', 'code_39_vin_reader', 'upc_reader', 'upc_e_reader', 'codabar_reader', 'i2of5_reader', '2of5_reader', 'code_93_reader'],
        },
        debug: {
            showCanvas: true,
            showPatches: true,
            showFoundPatches: true,
            showSkeleton: true,
            showLabels: true,
            showPatchLabels: true,
            showRemainingPatchLabels: true,
            boxFromPatches: {
                showTransformed: true,
                showTransformedBox: true,
                showBB: true
            }
        }
      }, (err) => {
        if (err) {
          console.log(err);
          errorMessage.value = 'Error initializing the scanner: ' + err;
          return;
        }

        Quagga.start();
        isScanning.value = true;
      });

      Quagga.onDetected(onDetected);



      /* Quagga.onDetected(async (data) => {
        console.log(data);
        
        if (isPaused.value) return;

        setTimeout(() => {
            isPaused.value = false;
            Quagga.start();
          }, 4000); // Resume scanning after 4 seconds

        test.value = data.codeResult.code;

        const code = data.codeResult.code;
        result.value = code;

        // Pause the scanner for 4 seconds
        isPaused.value = true;
        Quagga.pause();

        try {
          //const response = await axios.post('https://your-api-endpoint/cercaProdotti', { barcode: code });
          const response = await apiProdotti.searchProductByBarcode(code);

          if (response.status === 200 && response.data.status === 0 && response.data.data.length > 0) {
            products.value.push(response.data.data[0]);
          } else {
            openToast('Nessun prodotto trovato', 'toast_danger');
          }
        } catch (err) {
          console.error('API error:', err);
          openToast('Errore durante la ricerca del prodotto', 'toast_danger');
        } finally {
          console.log('fine scansione');
        }
      }); */
    }

    // Function to start scanning
    const startScanning = () => {
      if (!isScanning.value) {
        initQuagga();
      }
    };

    // Function to stop scanning
    const stopScanning = () => {
      if (isScanning.value) {
        Quagga.stop();
        isScanning.value = false;
      }
    };

    // Initialize on mount and cleanup on unmount
    onMounted(() => {
      initQuagga();
    });

    onBeforeUnmount(() => {
      Quagga.stop();
    });

      onIonViewWillLeave(() => {
        Quagga.stop();
      });

      return {
        menu,
        openMenu,
        scannerContainer,
        result,
        products,
        errorMessage,
        isPaused,
        test,
        // Functions
        startScanning,
        stopScanning
      };
  },
};
